import { useEffect, useState } from 'react';

export default function useScrollDirection() {
  const [scrollDirection, setScrollDirection] = useState('down');

  useEffect(() => {
    let prevScrollpos = window.pageYOffset;

    const onScroll = () => {
      const currentScrollPos = window.pageYOffset;
      if (prevScrollpos > currentScrollPos) {
        setScrollDirection('up');
      } else {
        setScrollDirection('down');
      }
      prevScrollpos = currentScrollPos;
    };

    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, [scrollDirection]);

  return scrollDirection;
}
