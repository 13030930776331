import { postConsents, getConsents, getConsentPurposes, fetchMarketingConsentIds } from './api';
import {
  CONSENT_PURPOSES,
  COMPLETE_PURCHASE,
  NO_PURCHASE,
  OTHER_COUNTRY,
  CONFIRMED,
  NOTGIVEN,
} from './constants';

export {
  postConsents,
  getConsents,
  getConsentPurposes,
  fetchMarketingConsentIds,
  CONSENT_PURPOSES,
  COMPLETE_PURCHASE,
  NO_PURCHASE,
  OTHER_COUNTRY,
  CONFIRMED,
  NOTGIVEN,
};
