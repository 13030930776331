'use client';

import { usePathname } from 'next/navigation';
import { useEffect } from 'react';

export default function ScrollObserver() {
  const pathname = usePathname();

  let animateElements = [];

  function setScroll() {
    animateElements = document.querySelectorAll('.animate-on-scroll');
    const { clientHeight } = document.documentElement;
    animateElements.forEach((el) =>
      el.style.setProperty(
        '--scroll',
        document.documentElement.scrollTop < clientHeight ? document.documentElement.scrollTop : clientHeight,
      ),
    );
  }

  function observeScrolling() {
    const menuChangeTrigger = document.querySelector('[data-menu-change]');

    const options = {
      rootMargin: '-125px 0px 2000px',
    };

    const onIntersect = (entries) => {
      entries.forEach((entry) => {
        const style = entry.isIntersecting ? 'transparent' : 'light';
        document.documentElement.setAttribute('data-header', style);
      });
    };

    const observer = new IntersectionObserver(onIntersect, options);

    if (menuChangeTrigger) {
      observer.observe(menuChangeTrigger);
    } else {
      document.documentElement.setAttribute('data-header', 'light');
    }
  }

  useEffect(() => {
    setScroll();
    observeScrolling();
    window.addEventListener('scroll', setScroll);
    window.addEventListener('resize', setScroll);

    return () => {
      window.removeEventListener('scroll', setScroll);
      window.removeEventListener('resize', setScroll);
    };
  }, [pathname]);

  return null;
}
