'use client';

import Link from 'next/link';
import LOCATION_PATHS from '@lingo/adobe-analytics';
import { replaceUrls } from 'utils/urls';
import { LingoHeaderLogo } from '../../icons';
import MenuButton from './MenuButton';
import './style.scss';
import cn from '../../lib/utils';
import useScrollDirection from '../hooks/useScrollDirection';
import SideNavigation from './SideNavigation';

export default function NavigationClientWrapper({
  menuData: {
    menuOptions: { items: menuItems = [] } = {},
    lingoHomeUrl = '/',
    ctaDesktopText = '',
    ctaDesktopLink = '/',
    ctaMobileText = '',
    ctaMobileLink = '/',
  } = {},
  jsonMap,
  env,
  showCTA = false,
}) {
  const scrollDirection = useScrollDirection();

  const updatedLingoHomeUrl = replaceUrls(lingoHomeUrl, env);
  const updatedCtaDesktopLink = replaceUrls(ctaDesktopLink, env);
  const updatedCtaMobileLink = replaceUrls(ctaMobileLink, env);

  return (
    <nav
      className={cn(
        'top-navigation sticky top-0 z-30 transition-all duration-500',
        scrollDirection === 'down' ? '-top-32 md:top-0' : 'top-0',
      )}
      data-analytics-location={LOCATION_PATHS.header}
    >
      <div className='container py-0 md:py-5 flex justify-between items-center'>
        <div className='flex items-center gap-10'>
          <Link
            className='logo white my-4'
            href={updatedLingoHomeUrl}
            data-analytics-action='home'
            data-analytics-location={LOCATION_PATHS.header}
            data-testid='headerLogo'
          >
            <LingoHeaderLogo color='#fff' width='77px' height='30px' alt='Lingo' />
          </Link>
          <Link
            className='logo dark my-4'
            href={updatedLingoHomeUrl}
            data-analytics-action='home'
            data-analytics-location={LOCATION_PATHS.header}
            data-testid='headerLogo'
          >
            <LingoHeaderLogo color='#002A3A' width='77px' height='30px' alt='Lingo' />
          </Link>
          <div className='hidden md:flex items-center gap-10'>
            {menuItems.map(({ title, link }) => (
              <Link
                key={link}
                className='link'
                href={replaceUrls(link, env)}
                data-analytics-action={title}
                data-analytics-location={LOCATION_PATHS.header}
              >
                {title}
              </Link>
            ))}
          </div>
        </div>

        {/* REFACTOR ALL BUTTONS LATER */}
        {showCTA && (
          <div className='hidden md:flex'>
            <Link
              className='button-light button white grow-0 min-w-36 inline-flex justify-center'
              href={updatedCtaDesktopLink}
              variant='menu-white'
              data-analytics-action={ctaDesktopText}
              data-analytics-location={LOCATION_PATHS.header}
            >
              {ctaDesktopText}
            </Link>
            <Link
              className='button-dark button dark grow-0 min-w-36 inline-flex justify-center'
              href={updatedCtaDesktopLink}
              variant='menu-dark'
              data-analytics-action={ctaDesktopText}
              data-analytics-location={LOCATION_PATHS.header}
            >
              {ctaDesktopText}
            </Link>
          </div>
        )}

        <div className='flex md:hidden gap-10 items-center'>
          {showCTA && (
            <>
              <Link
                className='button-outline button dark grow-0 inline-flex justify-center my-0 py-2.5'
                href={updatedCtaMobileLink}
                variant='menu-dark-mobile'
                data-analytics-action={ctaMobileText}
                data-analytics-location={LOCATION_PATHS.header}
              >
                {ctaMobileText}
              </Link>
              <Link
                className='button-outline-light button white grow-0 inline-flex justify-center my-0 py-2.5'
                href={updatedCtaMobileLink}
                variant='menu-white-mobile'
                data-analytics-action={ctaMobileText}
                data-analytics-location={LOCATION_PATHS.header}
              >
                {ctaMobileText}
              </Link>
            </>
          )}

          <div className='menu-button'>
            <MenuButton icon='hamburger' />
          </div>
        </div>
      </div>

      {/* Side Menu */}
      <SideNavigation
        homeUrl={updatedLingoHomeUrl}
        menuItems={menuItems}
        blogCategories={jsonMap.categories}
        env={env}
      />
    </nav>
  );
}
