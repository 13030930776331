'use client';

import LOCATION_PATHS from '@lingo/adobe-analytics';
import { useEffect } from 'react';
import { usePathname } from 'next/navigation';
import Link from 'next/link';
import { replaceUrls } from 'utils/urls';
import { LingoHeaderLogo } from '../../icons';
import MenuButton from './MenuButton';
import './style.scss';

export default function SideNavigation({ homeUrl, menuItems, blogCategories, env }) {
  const pathname = usePathname();

  useEffect(() => {
    const menu = document.documentElement;
    menu.classList.remove('open-menu');
  }, [pathname]);

  return (
    <div className='side-menu bg-blue-900 block md:none inset-0'>
      <div className='px-4 py-3 flex justify-between items-center'>
        <Link
          className='logo white'
          href={homeUrl}
          data-analytics-action='home'
          data-analytics-location={LOCATION_PATHS.header}
          data-testid='headerLogo'
          style={{ paddingTop: '4px' }}
        >
          <LingoHeaderLogo color='#fff' width='77px' height='30px' alt='Lingo' />
        </Link>

        <MenuButton icon='close' />
      </div>
      <div className='px-4'>
        {menuItems.map(({ title, link }) => {
          const envLink = replaceUrls(link, env);
          return (
            <Link
              key={envLink}
              className='block text-white h3 py-4 border-t'
              href={envLink}
              data-analytics-action={title}
              data-analytics-location={LOCATION_PATHS.header}
            >
              {title}
            </Link>
          );
        })}
        <div className='border-l pl-3'>
          {blogCategories?.map((cat) => {
            const envLink = replaceUrls(`https://www.hellolingo.com/topic/${cat.url}`, env);
            return (
              <Link
                key={envLink}
                className='block py-2 first:pt-0 last:pb-0 text-brown-100 hover:underline decoration-1 decoration-transparent md:hover:decoration-brown-100 underline-offset-4 transition-all duration-200'
                href={envLink}
              >
                {cat.name}
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
}
