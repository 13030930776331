'use client';

import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

export default function MenuButton({ icon = '', color = '#ffffff' }) {
  const handleClick = () => {
    const menu = document.documentElement;
    menu.classList.toggle('open-menu');
  };

  return icon === 'close' ? (
    <CloseIcon sx={{ color, cursor: 'pointer' }} onClick={handleClick} />
  ) : (
    <MenuIcon sx={{ cursor: 'pointer' }} onClick={handleClick} />
  );
}
