'use client';

/*
 * This is a copy from Home component
 * REQUIRES REFACTORING to have 1 common version
 * (with a high attention to separate postConsent() functions from both apps)
 */

import { GEOLOCATION_ALLOWED, GEOLOCATION_NOT_ALLOWED, X_GEO_LOCATION_STATUS } from 'utils';
import { LinkTypography } from 'ui';
import { getPhrases } from 'ui/helpers';
import { useEffect, useState } from 'react';
import { getCookie } from 'cookies-next';
import EmailEntryVariant from './textFieldEntry';

export default function Signup({
  signupContent = [],
  brazeCustomUserAttributes = {},
  brazeConfig = {},
  brazeSignupSource = '',
}) {
  const [geoLocationStatus, setGeoLocationStatus] = useState(GEOLOCATION_ALLOWED);

  const {
    subtitle = '',
    title = '',
    emailSuccessMessage = '',
    emailErrorMessage = '',
    disclaimerText = {},
    agreementLinks = {},
    buttonText = '',
  } = signupContent.find((item) => item.contentGroup === geoLocationStatus) ?? {};

  useEffect(() => {
    setGeoLocationStatus(getCookie(X_GEO_LOCATION_STATUS) || GEOLOCATION_ALLOWED);
  }, []);

  if (geoLocationStatus === GEOLOCATION_NOT_ALLOWED) return null;

  const privacyNoticeParts = getPhrases(disclaimerText?.plaintext, agreementLinks);

  return (
    <>
      <div className='col-span-4 md:col-span-5'>
        <h2 data-testid='signup-title' className='mb-2 md:mb-4'>
          {title}
        </h2>
      </div>

      <div className='col-span-4 md:col-start-7 md:col-span-5' data-testid='signup-subtitle'>
        {subtitle}
      </div>

      <div className='col-span-4 md:col-span-5'>
        <EmailEntryVariant
          brazeConfig={brazeConfig}
          emailSuccessMessage={emailSuccessMessage}
          emailErrorMessage={emailErrorMessage}
          buttonText={buttonText}
          brazeCustomUserAttributes={brazeCustomUserAttributes}
          brazeSignupSource={brazeSignupSource}
        />
      </div>
      <div className='col-span-4 md:col-start-7 md:col-span-5 small font-matter leading-6'>
        <LinkTypography
          variant='bodySM'
          color='colorTheme.contentPrimaryOnLight'
          data-testid='signUpSectionAgreement'
          parts={privacyNoticeParts}
          aemData={{ agreementLinks }}
          keyWord='parts'
          styles={{
            width: { xs: 1, lg: 4 / 10 },
            maxWidth: { xs: 1, lg: 4 / 10 },
            fontFamily: 'inherit',
            fontSize: 'inherit',
            lineHeight: 'inherit',
          }}
        />
      </div>
    </>
  );
}
