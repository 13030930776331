import axios from 'axios';
import getAccessToken from './auth';
import { CONSENT_PURPOSES, CONFIRMED, NOTGIVEN, WITHDRAWN } from './constants';

export async function postConsents(url, token, consentPurposes, dataSubject) {
  const headers = {
    'Content-Type': 'application/json',
    accept: 'application/json',
    Authorization: `Bearer ${token}`,
  };

  const data = {
    identifier: dataSubject,
    requestInformation: token,
    purposes: consentPurposes,
  };

  const result = await axios.post(url, data, { headers });
  return result.status;
}

export async function getConsents(config, userId) {
  const { ONE_TRUST_AUTH_URL, ONE_TRUST_CLIENT_ID, ONE_TRUST_CLIENT_SECRET, ONE_TRUST_DATASUBJECT_URL } =
    config;

  const accessToken = await getAccessToken(ONE_TRUST_AUTH_URL, ONE_TRUST_CLIENT_ID, ONE_TRUST_CLIENT_SECRET);

  const headers = {
    'Content-Type': 'application/json',
    accept: 'application/json',
    Authorization: `Bearer ${accessToken}`,
    identifier: userId,
  };

  const result = await axios.get(`${ONE_TRUST_DATASUBJECT_URL}`, { headers });
  return result.data;
}

export function getConsentPurposes(lingoEnv, collectionPoint, hasMarketingConsent = false) {
  const purposes = CONSENT_PURPOSES[lingoEnv];
  const collectionPoints = {
    COMPLETE_PURCHASE: [
      {
        Id: purposes.marketing,
        TransactionType: hasMarketingConsent === true ? CONFIRMED : NOTGIVEN,
      },
      {
        Id: purposes.privacyNotice,
        TransactionType: CONFIRMED,
      },
      {
        Id: purposes.termsOfSale,
        TransactionType: CONFIRMED,
      },
    ],
    NO_PURCHASE: [
      {
        Id: purposes.marketingGeneral,
        TransactionType: CONFIRMED,
      },
      {
        Id: purposes.privacyNotice,
        TransactionType: CONFIRMED,
      },
    ],
    OTHER_COUNTRY: [
      {
        Id: purposes.marketingNonUk,
        TransactionType: CONFIRMED,
      },
      {
        Id: purposes.privacyNotice,
        TransactionType: CONFIRMED,
      },
    ],
    UNSUBSCRIBE: [
      {
        Id: purposes.marketingNonUk,
        TransactionType: WITHDRAWN,
      },
      {
        Id: purposes.marketing,
        TransactionType: WITHDRAWN,
      },
      {
        Id: purposes.marketingGeneral,
        TransactionType: WITHDRAWN,
      },
    ],
  };

  return collectionPoints[collectionPoint];
}

export function fetchMarketingConsentIds(lingoEnv) {
  const purposes = CONSENT_PURPOSES[lingoEnv];
  return [purposes.marketing, purposes.marketingGeneral, purposes.marketingNonUk];
}
