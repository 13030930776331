'use client';

import clsx from 'clsx';

export default function SignupStatusMessage({ isError, isSuccess, successMessage, errorMessage }) {
  const messageClasses = clsx('px-4 py-2 small text-white', {
    'bg-green-500': isSuccess,
    'bg-red-500': isError,
  });

  let content = '';
  if (isError) {
    content = errorMessage;
  } else if (isSuccess) {
    content = successMessage;
  }

  if (!isError && !isSuccess) return null;

  return (
    <div data-testid='signupStatusMessage' className={messageClasses}>
      {content}
    </div>
  );
}
